let api_key = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFwcF9pbnRlcmlvckB3aW5nbWFjaHQuZWUiLCJmaXJzdG5hbWUiOm51bGwsImxhc3RuYW1lIjpudWxsLCJpZCI6OCwicm9sZXMiOiJ1c2VyIiwiaWF0IjoxNjQ4MDI2NjY2fQ.o8thq4oXWdrlVaOzEBtp9wlfrlySwwX0ecXVXA-gllY'

export function api_url() {
    return 'https://ncdb.surflink.ee/nc/wcrm_WSoY/api/v1/'
}

export function api_get(url) {
    return fetch(api_url()+url, {  headers: {'xc-auth': api_key }})
}

export function api_post(url, body) {
    return fetch(api_url()+url, {  method: 'POST', headers: {'xc-auth': api_key, 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
}

export function api_put(url, body) {
    return fetch(api_url()+url, {  method: 'PUT', headers: {'xc-auth': api_key, 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
}

export function gen_link_customer(uuid, is_dev=false) {
	return "https://client.wingmacht.ee/?link=" + uuid
}

// post msg to ncdb, trigger sending from nocodb
export function sendEmail(to, to_name, subject, textpart, htmlpart) {
    var body = {
        "to": to, "to_name": to_name,
        "subject": subject,
        "textpart": textpart,
        "htmlpart": htmlpart
    }
    fetch( 'https://ncdb.surflink.ee/nc/wcrm_WSoY/api/v1/msg',
        {  method: 'POST', headers: {'xc-auth': api_key, 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
			.then(response => response.json())
			.then(data => {
				//alert(JSON.stringify(data))
				})

}