/* App.svelte generated by Svelte v3.43.1 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	noop,
	safe_not_equal,
	set_data,
	set_store_value,
	space,
	text,
	toggle_class,
	transition_in,
	transition_out
} from "./_snowpack/pkg/svelte/internal.js";

import { Router, Route, Link } from "./_snowpack/pkg/svelte-navigator.js";
import PrivateRoute from "./PrivateRoute.svelte.js";
import { user, user_data } from "./stores.js";
import { is_DEV } from "./stores.js";
import Login from "./Login.svelte.js";
import Garantii from "./Garantii.svelte.js";
import TaskEdit from "./TaskEdit.svelte.js";

function create_default_slot_10(ctx) {
	let t;

	return {
		c() {
			t = text("/");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (51:7) {#if $user}
function create_if_block_3(ctx) {
	let link;
	let current;

	link = new Link({
			props: {
				class: "navbar-item",
				to: "garantii",
				$$slots: { default: [create_default_slot_9] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(link.$$.fragment);
		},
		m(target, anchor) {
			mount_component(link, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(link.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(link.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(link, detaching);
		}
	};
}

// (52:11) <Link class="navbar-item" to="garantii">
function create_default_slot_9(ctx) {
	let t;

	return {
		c() {
			t = text("Garantii");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (56:5) {#if $user_data}
function create_if_block_2(ctx) {
	let link;
	let current;

	link = new Link({
			props: {
				class: "navbar-item",
				to: "profile",
				$$slots: { default: [create_default_slot_8] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(link.$$.fragment);
		},
		m(target, anchor) {
			mount_component(link, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const link_changes = {};

			if (dirty & /*$$scope, $user_data*/ 1026) {
				link_changes.$$scope = { dirty, ctx };
			}

			link.$set(link_changes);
		},
		i(local) {
			if (current) return;
			transition_in(link.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(link.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(link, detaching);
		}
	};
}

// (57:6) <Link class="navbar-item" to="profile">
function create_default_slot_8(ctx) {
	let t_value = /*$user_data*/ ctx[1].Title + "";
	let t;

	return {
		c() {
			t = text(t_value);
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*$user_data*/ 2 && t_value !== (t_value = /*$user_data*/ ctx[1].Title + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (66:1) <Route path="login">
function create_default_slot_7(ctx) {
	let login;
	let current;
	login = new Login({});

	return {
		c() {
			create_component(login.$$.fragment);
		},
		m(target, anchor) {
			mount_component(login, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(login.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(login.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(login, detaching);
		}
	};
}

// (71:2) {#if $user}
function create_if_block_1(ctx) {
	let h2;
	let t1;
	let div4;
	let div3;
	let div2;
	let div1;
	let div0;
	let link;
	let current;

	link = new Link({
			props: {
				to: "garantii",
				$$slots: { default: [create_default_slot_6] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			h2 = element("h2");
			h2.textContent = "Valikud:";
			t1 = space();
			div4 = element("div");
			div3 = element("div");
			div2 = element("div");
			div1 = element("div");
			div0 = element("div");
			create_component(link.$$.fragment);
			attr(h2, "class", "title");
			attr(div0, "class", "content");
			attr(div1, "class", "card-content");
			attr(div2, "class", "card");
			attr(div3, "class", "column");
			attr(div4, "class", "columns");
		},
		m(target, anchor) {
			insert(target, h2, anchor);
			insert(target, t1, anchor);
			insert(target, div4, anchor);
			append(div4, div3);
			append(div3, div2);
			append(div2, div1);
			append(div1, div0);
			mount_component(link, div0, null);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(link.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(link.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(h2);
			if (detaching) detach(t1);
			if (detaching) detach(div4);
			destroy_component(link);
		}
	};
}

// (78:28) <Link to="garantii">
function create_default_slot_6(ctx) {
	let span3;

	return {
		c() {
			span3 = element("span");

			span3.innerHTML = `<span class="icon"><span class="mdi mdi-account-question-outline"></span></span> 
                                  <span>Garantii</span>`;

			attr(span3, "class", "icon-text");
		},
		m(target, anchor) {
			insert(target, span3, anchor);
		},
		d(detaching) {
			if (detaching) detach(span3);
		}
	};
}

// (70:4) <PrivateRoute path="/">
function create_default_slot_5(ctx) {
	let if_block_anchor;
	let current;
	let if_block = /*$user*/ ctx[2] && create_if_block_1(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (/*$user*/ ctx[2]) {
				if (if_block) {
					if (dirty & /*$user*/ 4) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block_1(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

// (96:8) <PrivateRoute path="thankyou" let:location>
function create_default_slot_4(ctx) {
	let h2;

	return {
		c() {
			h2 = element("h2");
			h2.textContent = "Andmed saadetud, Täname!";
			attr(h2, "class", "subtitle");
		},
		m(target, anchor) {
			insert(target, h2, anchor);
		},
		d(detaching) {
			if (detaching) detach(h2);
		}
	};
}

// (100:4) <PrivateRoute path="profile" let:location>
function create_default_slot_3(ctx) {
	let h2;
	let t1;
	let div1;
	let div0;
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			h2 = element("h2");
			h2.textContent = "Login ok!";
			t1 = space();
			div1 = element("div");
			div0 = element("div");
			button = element("button");
			button.textContent = "Logout";
			attr(h2, "class", "subtitle");
			attr(button, "class", "button");
			attr(div0, "class", "control");
			attr(div1, "class", "field is-grouped");
		},
		m(target, anchor) {
			insert(target, h2, anchor);
			insert(target, t1, anchor);
			insert(target, div1, anchor);
			append(div1, div0);
			append(div0, button);

			if (!mounted) {
				dispose = listen(button, "click", /*handleLogout*/ ctx[4]);
				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(h2);
			if (detaching) detach(t1);
			if (detaching) detach(div1);
			mounted = false;
			dispose();
		}
	};
}

// (113:1) <PrivateRoute path="garantii" let:location let:params>
function create_default_slot_2(ctx) {
	let garantii;
	let current;
	garantii = new Garantii({});

	return {
		c() {
			create_component(garantii.$$.fragment);
		},
		m(target, anchor) {
			mount_component(garantii, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(garantii.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(garantii.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(garantii, detaching);
		}
	};
}

// (117:1) <PrivateRoute path="task/:id/edit" let:location let:params>
function create_default_slot_1(ctx) {
	let taskedit;
	let current;
	taskedit = new TaskEdit({});

	return {
		c() {
			create_component(taskedit.$$.fragment);
		},
		m(target, anchor) {
			mount_component(taskedit, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(taskedit.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(taskedit.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(taskedit, detaching);
		}
	};
}

// (35:0) <Router>
function create_default_slot(ctx) {
	let header;
	let nav;
	let div0;
	let a0;
	let t0;
	let a1;
	let t3;
	let div3;
	let div1;
	let link;
	let t4;
	let t5;
	let div2;
	let t6;
	let main;
	let route;
	let t7;
	let privateroute0;
	let t8;
	let privateroute1;
	let t9;
	let privateroute2;
	let t10;
	let privateroute3;
	let t11;
	let privateroute4;
	let current;
	let mounted;
	let dispose;

	link = new Link({
			props: {
				class: "navbar-item",
				to: "/",
				$$slots: { default: [create_default_slot_10] },
				$$scope: { ctx }
			}
		});

	let if_block0 = /*$user*/ ctx[2] && create_if_block_3(ctx);
	let if_block1 = /*$user_data*/ ctx[1] && create_if_block_2(ctx);

	route = new Route({
			props: {
				path: "login",
				$$slots: { default: [create_default_slot_7] },
				$$scope: { ctx }
			}
		});

	privateroute0 = new PrivateRoute({
			props: {
				path: "/",
				$$slots: { default: [create_default_slot_5] },
				$$scope: { ctx }
			}
		});

	privateroute1 = new PrivateRoute({
			props: {
				path: "thankyou",
				$$slots: {
					default: [
						create_default_slot_4,
						({ location }) => ({ 8: location }),
						({ location }) => location ? 256 : 0
					]
				},
				$$scope: { ctx }
			}
		});

	privateroute2 = new PrivateRoute({
			props: {
				path: "profile",
				$$slots: {
					default: [
						create_default_slot_3,
						({ location }) => ({ 8: location }),
						({ location }) => location ? 256 : 0
					]
				},
				$$scope: { ctx }
			}
		});

	privateroute3 = new PrivateRoute({
			props: {
				path: "garantii",
				$$slots: {
					default: [
						create_default_slot_2,
						({ location, params }) => ({ 8: location, 9: params }),
						({ location, params }) => (location ? 256 : 0) | (params ? 512 : 0)
					]
				},
				$$scope: { ctx }
			}
		});

	privateroute4 = new PrivateRoute({
			props: {
				path: "task/:id/edit",
				$$slots: {
					default: [
						create_default_slot_1,
						({ location, params }) => ({ 8: location, 9: params }),
						({ location, params }) => (location ? 256 : 0) | (params ? 512 : 0)
					]
				},
				$$scope: { ctx }
			}
		});

	return {
		c() {
			header = element("header");
			nav = element("nav");
			div0 = element("div");
			a0 = element("a");
			a0.innerHTML = `<img src="/img/logo.png"/>`;
			t0 = space();
			a1 = element("a");

			a1.innerHTML = `<span aria-hidden="true"></span> 
					<span aria-hidden="true"></span> 
					<span aria-hidden="true"></span>`;

			t3 = space();
			div3 = element("div");
			div1 = element("div");
			create_component(link.$$.fragment);
			t4 = space();
			if (if_block0) if_block0.c();
			t5 = space();
			div2 = element("div");
			if (if_block1) if_block1.c();
			t6 = space();
			main = element("main");
			create_component(route.$$.fragment);
			t7 = space();
			create_component(privateroute0.$$.fragment);
			t8 = space();
			create_component(privateroute1.$$.fragment);
			t9 = space();
			create_component(privateroute2.$$.fragment);
			t10 = space();
			create_component(privateroute3.$$.fragment);
			t11 = space();
			create_component(privateroute4.$$.fragment);
			attr(a0, "class", "navbar-item");
			attr(a0, "href", "#");
			attr(a1, "role", "button");
			attr(a1, "class", "navbar-burger");
			attr(a1, "aria-label", "menu");
			attr(a1, "aria-expanded", "false");
			attr(a1, "data-target", "navbarBasicExample");
			attr(div0, "class", "navbar-brand");
			attr(div1, "class", "navbar-start");
			attr(div2, "class", "navbar-end");
			attr(div3, "id", "navbarBasicExample");
			attr(div3, "class", "navbar-menu");
			toggle_class(div3, "is-active", /*navbar_active*/ ctx[0]);
			attr(nav, "class", "navbar");
			attr(nav, "role", "navigation");
			attr(nav, "aria-label", "main navigation");
			attr(main, "class", "container is-fluid");
		},
		m(target, anchor) {
			insert(target, header, anchor);
			append(header, nav);
			append(nav, div0);
			append(div0, a0);
			append(div0, t0);
			append(div0, a1);
			append(nav, t3);
			append(nav, div3);
			append(div3, div1);
			mount_component(link, div1, null);
			append(div1, t4);
			if (if_block0) if_block0.m(div1, null);
			append(div3, t5);
			append(div3, div2);
			if (if_block1) if_block1.m(div2, null);
			insert(target, t6, anchor);
			insert(target, main, anchor);
			mount_component(route, main, null);
			append(main, t7);
			mount_component(privateroute0, main, null);
			append(main, t8);
			mount_component(privateroute1, main, null);
			append(main, t9);
			mount_component(privateroute2, main, null);
			append(main, t10);
			mount_component(privateroute3, main, null);
			append(main, t11);
			mount_component(privateroute4, main, null);
			current = true;

			if (!mounted) {
				dispose = listen(a1, "click", /*click_handler*/ ctx[5]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			const link_changes = {};

			if (dirty & /*$$scope*/ 1024) {
				link_changes.$$scope = { dirty, ctx };
			}

			link.$set(link_changes);

			if (/*$user*/ ctx[2]) {
				if (if_block0) {
					if (dirty & /*$user*/ 4) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_3(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(div1, null);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (/*$user_data*/ ctx[1]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty & /*$user_data*/ 2) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_2(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div2, null);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (dirty & /*navbar_active*/ 1) {
				toggle_class(div3, "is-active", /*navbar_active*/ ctx[0]);
			}

			const route_changes = {};

			if (dirty & /*$$scope*/ 1024) {
				route_changes.$$scope = { dirty, ctx };
			}

			route.$set(route_changes);
			const privateroute0_changes = {};

			if (dirty & /*$$scope, $user*/ 1028) {
				privateroute0_changes.$$scope = { dirty, ctx };
			}

			privateroute0.$set(privateroute0_changes);
			const privateroute1_changes = {};

			if (dirty & /*$$scope*/ 1024) {
				privateroute1_changes.$$scope = { dirty, ctx };
			}

			privateroute1.$set(privateroute1_changes);
			const privateroute2_changes = {};

			if (dirty & /*$$scope*/ 1024) {
				privateroute2_changes.$$scope = { dirty, ctx };
			}

			privateroute2.$set(privateroute2_changes);
			const privateroute3_changes = {};

			if (dirty & /*$$scope*/ 1024) {
				privateroute3_changes.$$scope = { dirty, ctx };
			}

			privateroute3.$set(privateroute3_changes);
			const privateroute4_changes = {};

			if (dirty & /*$$scope*/ 1024) {
				privateroute4_changes.$$scope = { dirty, ctx };
			}

			privateroute4.$set(privateroute4_changes);
		},
		i(local) {
			if (current) return;
			transition_in(link.$$.fragment, local);
			transition_in(if_block0);
			transition_in(if_block1);
			transition_in(route.$$.fragment, local);
			transition_in(privateroute0.$$.fragment, local);
			transition_in(privateroute1.$$.fragment, local);
			transition_in(privateroute2.$$.fragment, local);
			transition_in(privateroute3.$$.fragment, local);
			transition_in(privateroute4.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(link.$$.fragment, local);
			transition_out(if_block0);
			transition_out(if_block1);
			transition_out(route.$$.fragment, local);
			transition_out(privateroute0.$$.fragment, local);
			transition_out(privateroute1.$$.fragment, local);
			transition_out(privateroute2.$$.fragment, local);
			transition_out(privateroute3.$$.fragment, local);
			transition_out(privateroute4.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(header);
			destroy_component(link);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			if (detaching) detach(t6);
			if (detaching) detach(main);
			destroy_component(route);
			destroy_component(privateroute0);
			destroy_component(privateroute1);
			destroy_component(privateroute2);
			destroy_component(privateroute3);
			destroy_component(privateroute4);
			mounted = false;
			dispose();
		}
	};
}

// (125:0) {#if ($is_DEV===true) }
function create_if_block(ctx) {
	let pre;
	let t_value = JSON.stringify(/*$user_data*/ ctx[1], null, 4) + "";
	let t;

	return {
		c() {
			pre = element("pre");
			t = text(t_value);
		},
		m(target, anchor) {
			insert(target, pre, anchor);
			append(pre, t);
		},
		p(ctx, dirty) {
			if (dirty & /*$user_data*/ 2 && t_value !== (t_value = JSON.stringify(/*$user_data*/ ctx[1], null, 4) + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(pre);
		}
	};
}

function create_fragment(ctx) {
	let router;
	let t;
	let if_block_anchor;
	let current;

	router = new Router({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	let if_block = /*$is_DEV*/ ctx[3] === true && create_if_block(ctx);

	return {
		c() {
			create_component(router.$$.fragment);
			t = space();
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			mount_component(router, target, anchor);
			insert(target, t, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const router_changes = {};

			if (dirty & /*$$scope, $user, navbar_active, $user_data*/ 1031) {
				router_changes.$$scope = { dirty, ctx };
			}

			router.$set(router_changes);

			if (/*$is_DEV*/ ctx[3] === true) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i(local) {
			if (current) return;
			transition_in(router.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(router.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(router, detaching);
			if (detaching) detach(t);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

let api_key = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFwcF9pbnRlcmlvckB3aW5nbWFjaHQuZWUiLCJmaXJzdG5hbWUiOm51bGwsImxhc3RuYW1lIjpudWxsLCJpZCI6OCwicm9sZXMiOiJ1c2VyIiwiaWF0IjoxNjQ4MDI2NjY2fQ.o8thq4oXWdrlVaOzEBtp9wlfrlySwwX0ecXVXA-gllY';

function instance($$self, $$props, $$invalidate) {
	let $user_data;
	let $user;
	let $is_DEV;
	component_subscribe($$self, user_data, $$value => $$invalidate(1, $user_data = $$value));
	component_subscribe($$self, user, $$value => $$invalidate(2, $user = $$value));
	component_subscribe($$self, is_DEV, $$value => $$invalidate(3, $is_DEV = $$value));
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	set_store_value(user, $user = urlParams.get('link'), $user);

	fetch("https://ncdb.surflink.ee/nc/wcrm_WSoY/api/v1/worker/findOne?where=" + encodeURIComponent('(link,eq,' + $user + ')'), { headers: { 'xc-auth': api_key } }).then(response => response.json()).then(data => {
		set_store_value(user_data, $user_data = data, $user_data);
	});

	if (window.location.host == 'dev.local:9091') {
		set_store_value(is_DEV, $is_DEV = true, $is_DEV);
	}

	function handleLogout() {
		set_store_value(user, $user = null, $user);
		set_store_value(user_data, $user_data = null, $user_data);
	}

	let navbar_active = false;
	const click_handler = () => $$invalidate(0, navbar_active = !navbar_active);
	return [navbar_active, $user_data, $user, $is_DEV, handleLogout, click_handler];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;