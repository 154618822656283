import './TaskEdit.svelte.css';
/* TaskEdit.svelte generated by Svelte v3.43.1 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	destroy_each,
	detach,
	element,
	empty,
	init,
	insert,
	is_function,
	listen,
	noop,
	safe_not_equal,
	set_data,
	space,
	text
} from "./_snowpack/pkg/svelte/internal.js";

import { onMount } from './_snowpack/pkg/svelte.js';
import { user } from "./stores.js";
import { is_DEV } from "./stores.js";
import { api_get, api_put, sendEmail, gen_link_customer } from "./api.js";
import { Router, Route, Link } from "./_snowpack/pkg/svelte-navigator.js";
import { useParams, useNavigate, useLocation } from "./_snowpack/pkg/svelte-navigator.js";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[9] = list[i];
	child_ctx[11] = i;
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[9] = list[i];
	child_ctx[11] = i;
	return child_ctx;
}

function get_each_context_2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[9] = list[i];
	child_ctx[11] = i;
	return child_ctx;
}

function get_each_context_3(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[14] = list[i];
	return child_ctx;
}

// (105:0) {#if errors.length}
function create_if_block_7(ctx) {
	let article;
	let div;
	let ul;
	let each_value_3 = /*errors*/ ctx[1];
	let each_blocks = [];

	for (let i = 0; i < each_value_3.length; i += 1) {
		each_blocks[i] = create_each_block_3(get_each_context_3(ctx, each_value_3, i));
	}

	return {
		c() {
			article = element("article");
			div = element("div");
			ul = element("ul");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "class", "message-body");
			attr(article, "class", "message is-danger");
		},
		m(target, anchor) {
			insert(target, article, anchor);
			append(article, div);
			append(div, ul);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(ul, null);
			}
		},
		p(ctx, dirty) {
			if (dirty & /*errors*/ 2) {
				each_value_3 = /*errors*/ ctx[1];
				let i;

				for (i = 0; i < each_value_3.length; i += 1) {
					const child_ctx = get_each_context_3(ctx, each_value_3, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_3(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(ul, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_3.length;
			}
		},
		d(detaching) {
			if (detaching) detach(article);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (109:12) {#each errors as a}
function create_each_block_3(ctx) {
	let li;
	let t_value = /*a*/ ctx[14] + "";
	let t;

	return {
		c() {
			li = element("li");
			t = text(t_value);
		},
		m(target, anchor) {
			insert(target, li, anchor);
			append(li, t);
		},
		p(ctx, dirty) {
			if (dirty & /*errors*/ 2 && t_value !== (t_value = /*a*/ ctx[14] + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(li);
		}
	};
}

// (117:0) {#if task}
function create_if_block(ctx) {
	let h2;
	let t0;
	let t1_value = /*task*/ ctx[0].addr + "";
	let t1;
	let t2;
	let div1;
	let t4;
	let div3;
	let t5;
	let div5;
	let div4;
	let p0;
	let t6;
	let div7;
	let t8;
	let div9;
	let t9;
	let div11;
	let div10;
	let p1;
	let t10;
	let div13;
	let t12;
	let div15;
	let t13;
	let div17;
	let div16;
	let p2;
	let t14;
	let div18;
	let button;
	let mounted;
	let dispose;
	let each_value_2 = /*options_time*/ ctx[4];
	let each_blocks_2 = [];

	for (let i = 0; i < each_value_2.length; i += 1) {
		each_blocks_2[i] = create_each_block_2(get_each_context_2(ctx, each_value_2, i));
	}

	let each_value_1 = /*options_time*/ ctx[4];
	let each_blocks_1 = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks_1[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	let each_value = /*options_time*/ ctx[4];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			h2 = element("h2");
			t0 = text("Garantii. ");
			t1 = text(t1_value);
			t2 = space();
			div1 = element("div");
			div1.innerHTML = `<div class="control"><label class="label">Paku Kliendile Kuupäev ja Aeg #1</label></div>`;
			t4 = space();
			div3 = element("div");
			div3.innerHTML = `<div class="control"><input rel="date1" class="input" type="date" placeholder=""/></div>`;
			t5 = space();
			div5 = element("div");
			div4 = element("div");
			p0 = element("p");

			for (let i = 0; i < each_blocks_2.length; i += 1) {
				each_blocks_2[i].c();
			}

			t6 = space();
			div7 = element("div");
			div7.innerHTML = `<div class="control"><label class="label">Paku Kliendile Kuupäev ja Aeg #2</label></div>`;
			t8 = space();
			div9 = element("div");
			div9.innerHTML = `<div class="control"><input rel="date2" class="input" type="date" placeholder=""/></div>`;
			t9 = space();
			div11 = element("div");
			div10 = element("div");
			p1 = element("p");

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].c();
			}

			t10 = space();
			div13 = element("div");
			div13.innerHTML = `<div class="control"><label class="label">Paku Kliendile Kuupäev ja Aeg #3</label></div>`;
			t12 = space();
			div15 = element("div");
			div15.innerHTML = `<div class="control"><input rel="date3" class="input" type="date" placeholder=""/></div>`;
			t13 = space();
			div17 = element("div");
			div16 = element("div");
			p2 = element("p");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t14 = space();
			div18 = element("div");
			button = element("button");
			button.textContent = "Salvesta ja Saada ajad kliendile";
			attr(h2, "class", "subtitle");
			attr(div1, "class", "field");
			attr(div3, "class", "field");
			attr(p0, "class", "svelte-5tqa0k");
			attr(div4, "class", "control");
			attr(div5, "class", "field");
			attr(div7, "class", "field");
			attr(div9, "class", "field");
			attr(p1, "class", "svelte-5tqa0k");
			attr(div10, "class", "control");
			attr(div11, "class", "field");
			attr(div13, "class", "field");
			attr(div15, "class", "field");
			attr(p2, "class", "svelte-5tqa0k");
			attr(div16, "class", "control");
			attr(div17, "class", "field");
			attr(button, "class", "button is-primary");
			attr(div18, "class", "field");
		},
		m(target, anchor) {
			insert(target, h2, anchor);
			append(h2, t0);
			append(h2, t1);
			insert(target, t2, anchor);
			insert(target, div1, anchor);
			insert(target, t4, anchor);
			insert(target, div3, anchor);
			insert(target, t5, anchor);
			insert(target, div5, anchor);
			append(div5, div4);
			append(div4, p0);

			for (let i = 0; i < each_blocks_2.length; i += 1) {
				each_blocks_2[i].m(p0, null);
			}

			insert(target, t6, anchor);
			insert(target, div7, anchor);
			insert(target, t8, anchor);
			insert(target, div9, anchor);
			insert(target, t9, anchor);
			insert(target, div11, anchor);
			append(div11, div10);
			append(div10, p1);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].m(p1, null);
			}

			insert(target, t10, anchor);
			insert(target, div13, anchor);
			insert(target, t12, anchor);
			insert(target, div15, anchor);
			insert(target, t13, anchor);
			insert(target, div17, anchor);
			append(div17, div16);
			append(div16, p2);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(p2, null);
			}

			insert(target, t14, anchor);
			insert(target, div18, anchor);
			append(div18, button);

			if (!mounted) {
				dispose = listen(button, "click", /*handle_submit*/ ctx[5]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*task*/ 1 && t1_value !== (t1_value = /*task*/ ctx[0].addr + "")) set_data(t1, t1_value);

			if (dirty & /*options_time, task*/ 17) {
				each_value_2 = /*options_time*/ ctx[4];
				let i;

				for (i = 0; i < each_value_2.length; i += 1) {
					const child_ctx = get_each_context_2(ctx, each_value_2, i);

					if (each_blocks_2[i]) {
						each_blocks_2[i].p(child_ctx, dirty);
					} else {
						each_blocks_2[i] = create_each_block_2(child_ctx);
						each_blocks_2[i].c();
						each_blocks_2[i].m(p0, null);
					}
				}

				for (; i < each_blocks_2.length; i += 1) {
					each_blocks_2[i].d(1);
				}

				each_blocks_2.length = each_value_2.length;
			}

			if (dirty & /*options_time, task*/ 17) {
				each_value_1 = /*options_time*/ ctx[4];
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks_1[i]) {
						each_blocks_1[i].p(child_ctx, dirty);
					} else {
						each_blocks_1[i] = create_each_block_1(child_ctx);
						each_blocks_1[i].c();
						each_blocks_1[i].m(p1, null);
					}
				}

				for (; i < each_blocks_1.length; i += 1) {
					each_blocks_1[i].d(1);
				}

				each_blocks_1.length = each_value_1.length;
			}

			if (dirty & /*options_time, task*/ 17) {
				each_value = /*options_time*/ ctx[4];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(p2, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		d(detaching) {
			if (detaching) detach(h2);
			if (detaching) detach(t2);
			if (detaching) detach(div1);
			if (detaching) detach(t4);
			if (detaching) detach(div3);
			if (detaching) detach(t5);
			if (detaching) detach(div5);
			destroy_each(each_blocks_2, detaching);
			if (detaching) detach(t6);
			if (detaching) detach(div7);
			if (detaching) detach(t8);
			if (detaching) detach(div9);
			if (detaching) detach(t9);
			if (detaching) detach(div11);
			destroy_each(each_blocks_1, detaching);
			if (detaching) detach(t10);
			if (detaching) detach(div13);
			if (detaching) detach(t12);
			if (detaching) detach(div15);
			if (detaching) detach(t13);
			if (detaching) detach(div17);
			destroy_each(each_blocks, detaching);
			if (detaching) detach(t14);
			if (detaching) detach(div18);
			mounted = false;
			dispose();
		}
	};
}

// (134:12) {#if i>0}
function create_if_block_6(ctx) {
	let t;

	return {
		c() {
			t = text("  |  ");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (135:12) {#if i==options_time.length/2}
function create_if_block_5(ctx) {
	let br;

	return {
		c() {
			br = element("br");
		},
		m(target, anchor) {
			insert(target, br, anchor);
		},
		d(detaching) {
			if (detaching) detach(br);
		}
	};
}

// (133:8) {#each options_time as opt, i}
function create_each_block_2(ctx) {
	let t0;
	let t1;
	let label;
	let input;
	let input_value_value;
	let input_checked_value;
	let t2;
	let t3_value = /*opt*/ ctx[9] + "";
	let t3;
	let mounted;
	let dispose;
	let if_block0 = /*i*/ ctx[11] > 0 && create_if_block_6(ctx);
	let if_block1 = /*i*/ ctx[11] == /*options_time*/ ctx[4].length / 2 && create_if_block_5(ctx);

	return {
		c() {
			if (if_block0) if_block0.c();
			t0 = space();
			if (if_block1) if_block1.c();
			t1 = space();
			label = element("label");
			input = element("input");
			t2 = space();
			t3 = text(t3_value);
			attr(input, "type", "radio");
			attr(input, "name", "time1");
			input.value = input_value_value = /*opt*/ ctx[9];
			input.checked = input_checked_value = /*task*/ ctx[0].time1 === /*opt*/ ctx[9];
		},
		m(target, anchor) {
			if (if_block0) if_block0.m(target, anchor);
			insert(target, t0, anchor);
			if (if_block1) if_block1.m(target, anchor);
			insert(target, t1, anchor);
			insert(target, label, anchor);
			append(label, input);
			append(label, t2);
			append(label, t3);

			if (!mounted) {
				dispose = listen(input, "change", function () {
					if (is_function(/*task*/ ctx[0].time1 = /*opt*/ ctx[9])) (/*task*/ ctx[0].time1 = /*opt*/ ctx[9]).apply(this, arguments);
				});

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*task*/ 1 && input_checked_value !== (input_checked_value = /*task*/ ctx[0].time1 === /*opt*/ ctx[9])) {
				input.checked = input_checked_value;
			}
		},
		d(detaching) {
			if (if_block0) if_block0.d(detaching);
			if (detaching) detach(t0);
			if (if_block1) if_block1.d(detaching);
			if (detaching) detach(t1);
			if (detaching) detach(label);
			mounted = false;
			dispose();
		}
	};
}

// (157:12) {#if i>0}
function create_if_block_4(ctx) {
	let t;

	return {
		c() {
			t = text("  |  ");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (158:12) {#if i==options_time.length/2}
function create_if_block_3(ctx) {
	let br;

	return {
		c() {
			br = element("br");
		},
		m(target, anchor) {
			insert(target, br, anchor);
		},
		d(detaching) {
			if (detaching) detach(br);
		}
	};
}

// (156:8) {#each options_time as opt, i}
function create_each_block_1(ctx) {
	let t0;
	let t1;
	let label;
	let input;
	let input_value_value;
	let input_checked_value;
	let t2;
	let t3_value = /*opt*/ ctx[9] + "";
	let t3;
	let mounted;
	let dispose;
	let if_block0 = /*i*/ ctx[11] > 0 && create_if_block_4(ctx);
	let if_block1 = /*i*/ ctx[11] == /*options_time*/ ctx[4].length / 2 && create_if_block_3(ctx);

	return {
		c() {
			if (if_block0) if_block0.c();
			t0 = space();
			if (if_block1) if_block1.c();
			t1 = space();
			label = element("label");
			input = element("input");
			t2 = space();
			t3 = text(t3_value);
			attr(input, "type", "radio");
			attr(input, "name", "time2");
			input.value = input_value_value = /*opt*/ ctx[9];
			input.checked = input_checked_value = /*task*/ ctx[0].time2 === /*opt*/ ctx[9];
		},
		m(target, anchor) {
			if (if_block0) if_block0.m(target, anchor);
			insert(target, t0, anchor);
			if (if_block1) if_block1.m(target, anchor);
			insert(target, t1, anchor);
			insert(target, label, anchor);
			append(label, input);
			append(label, t2);
			append(label, t3);

			if (!mounted) {
				dispose = listen(input, "change", function () {
					if (is_function(/*task*/ ctx[0].time2 = /*opt*/ ctx[9])) (/*task*/ ctx[0].time2 = /*opt*/ ctx[9]).apply(this, arguments);
				});

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*task*/ 1 && input_checked_value !== (input_checked_value = /*task*/ ctx[0].time2 === /*opt*/ ctx[9])) {
				input.checked = input_checked_value;
			}
		},
		d(detaching) {
			if (if_block0) if_block0.d(detaching);
			if (detaching) detach(t0);
			if (if_block1) if_block1.d(detaching);
			if (detaching) detach(t1);
			if (detaching) detach(label);
			mounted = false;
			dispose();
		}
	};
}

// (180:12) {#if i>0}
function create_if_block_2(ctx) {
	let t;

	return {
		c() {
			t = text("  |  ");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (181:12) {#if i==options_time.length/2}
function create_if_block_1(ctx) {
	let br;

	return {
		c() {
			br = element("br");
		},
		m(target, anchor) {
			insert(target, br, anchor);
		},
		d(detaching) {
			if (detaching) detach(br);
		}
	};
}

// (179:8) {#each options_time as opt, i}
function create_each_block(ctx) {
	let t0;
	let t1;
	let label;
	let input;
	let input_value_value;
	let input_checked_value;
	let t2;
	let t3_value = /*opt*/ ctx[9] + "";
	let t3;
	let mounted;
	let dispose;
	let if_block0 = /*i*/ ctx[11] > 0 && create_if_block_2(ctx);
	let if_block1 = /*i*/ ctx[11] == /*options_time*/ ctx[4].length / 2 && create_if_block_1(ctx);

	return {
		c() {
			if (if_block0) if_block0.c();
			t0 = space();
			if (if_block1) if_block1.c();
			t1 = space();
			label = element("label");
			input = element("input");
			t2 = space();
			t3 = text(t3_value);
			attr(input, "type", "radio");
			attr(input, "name", "time3");
			input.value = input_value_value = /*opt*/ ctx[9];
			input.checked = input_checked_value = /*task*/ ctx[0].time3 === /*opt*/ ctx[9];
		},
		m(target, anchor) {
			if (if_block0) if_block0.m(target, anchor);
			insert(target, t0, anchor);
			if (if_block1) if_block1.m(target, anchor);
			insert(target, t1, anchor);
			insert(target, label, anchor);
			append(label, input);
			append(label, t2);
			append(label, t3);

			if (!mounted) {
				dispose = listen(input, "change", function () {
					if (is_function(/*task*/ ctx[0].time3 = /*opt*/ ctx[9])) (/*task*/ ctx[0].time3 = /*opt*/ ctx[9]).apply(this, arguments);
				});

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*task*/ 1 && input_checked_value !== (input_checked_value = /*task*/ ctx[0].time3 === /*opt*/ ctx[9])) {
				input.checked = input_checked_value;
			}
		},
		d(detaching) {
			if (if_block0) if_block0.d(detaching);
			if (detaching) detach(t0);
			if (if_block1) if_block1.d(detaching);
			if (detaching) detach(t1);
			if (detaching) detach(label);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let t;
	let if_block1_anchor;
	let if_block0 = /*errors*/ ctx[1].length && create_if_block_7(ctx);
	let if_block1 = /*task*/ ctx[0] && create_if_block(ctx);

	return {
		c() {
			if (if_block0) if_block0.c();
			t = space();
			if (if_block1) if_block1.c();
			if_block1_anchor = empty();
		},
		m(target, anchor) {
			if (if_block0) if_block0.m(target, anchor);
			insert(target, t, anchor);
			if (if_block1) if_block1.m(target, anchor);
			insert(target, if_block1_anchor, anchor);
		},
		p(ctx, [dirty]) {
			if (/*errors*/ ctx[1].length) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_7(ctx);
					if_block0.c();
					if_block0.m(t.parentNode, t);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*task*/ ctx[0]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block(ctx);
					if_block1.c();
					if_block1.m(if_block1_anchor.parentNode, if_block1_anchor);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (if_block0) if_block0.d(detaching);
			if (detaching) detach(t);
			if (if_block1) if_block1.d(detaching);
			if (detaching) detach(if_block1_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $location;
	let $params;
	const navigate = useNavigate();
	const location = useLocation();
	component_subscribe($$self, location, value => $$invalidate(6, $location = value));
	const params = useParams();
	component_subscribe($$self, params, value => $$invalidate(7, $params = value));
	let task = {};

	onMount(async () => {
		let today = new Date().toISOString().slice(0, 10);

		let calendars = bulmaCalendar.attach('[type="date"]', {
			dateFormat: "dd.MM.yyyy",
			weekStart: 1,
			minDate: today
		});

		api_get("task/" + $params.id).then(response => response.json()).then(data => {
			$$invalidate(0, task = data);

			calendars.forEach(calendar => {
				let rel = calendar.element.getAttribute('rel');
				let v = task[rel];
				calendar.value(v);

				calendar.on('save', function (datepicker) {
					console.log(datepicker.data.value());
					let rel = datepicker.data.element.getAttribute('rel');

					//                console.log(datepicker.timeStamp);
					//                item[rel] = datepicker.timeStamp;
					$$invalidate(0, task[rel] = datepicker.data.value(), task);
				});
			});
		});
	});

	let options_time = [
		"08:00 - 09:00",
		"09:00 - 10:00",
		"10:00 - 11:00",
		"11:00 - 12:00",
		"12:00 - 13:00",
		"13:00 - 14:00",
		"14:00 - 15:00",
		"15:00 - 16:00",
		"16:00 - 17:00",
		"17:00 - 18:00"
	];

	let errors = [];

	function handle_submit() {
		let body = {
			"date1": String(task.date1),
			"time1": String(task.time1),
			"date2": String(task.date2),
			"time2": String(task.time2),
			"date3": String(task.date3),
			"time3": String(task.time3),
			"color": "#f7c73d"
		};

		console.log(body);
		$$invalidate(1, errors = []);

		if ((/^\d+\.\d+\.\d\d\d\d$/).test(body.date1)) {
			
		} else {
			errors.push("Kuupäev 1 peaks olema valitud");
		}

		if ((/^\d\d:\d\d - \d\d:\d\d$/).test(body.time1)) {
			
		} else {
			errors.push("Ajavahemik 1 peaks olema valitud");
		}

		if ((/^\d+\.\d+\.\d\d\d\d$/).test(body.date2)) {
			
		} else {
			errors.push("Kuupäev 2 peaks olema valitud");
		}

		if ((/^\d\d:\d\d - \d\d:\d\d$/).test(body.time2)) {
			
		} else {
			errors.push("Ajavahemik 2 peaks olema valitud");
		}

		if ((/^\d+\.\d+\.\d\d\d\d$/).test(body.date3)) {
			
		} else {
			errors.push("Kuupäev 3 peaks olema valitud");
		}

		if ((/^\d\d:\d\d - \d\d:\d\d$/).test(body.time3)) {
			
		} else {
			errors.push("Ajavahemik 3 peaks olema valitud");
		}

		console.log(errors);
		$$invalidate(1, errors);

		if (errors.length == 0) {
			api_put("task/" + task.Id, body).then(response => response.json()).then(data => {
				let to_email = "andrei.gapejev@gmail.com";

				sendEmail(to_email, "", "ACRM: Garantitöö ajad. Palve valida sobiv aeg !", "", `Objekt: ${task.addr}
                <br/> Probleem: ${task.garantii_comment}
                <br/> Ajad:
                <br/> ${task.date1} ${task.time1}
                <br/> ${task.date2} ${task.time2}
                <br/> ${task.date3} ${task.time3}
                <br/> Link avamiseks: ` + gen_link_customer(task.link_customer, false) + "&dest=task&id=" + task.Id + `
                `);

				navigate("/thankyou", {
					state: { from: $location.pathname },
					replace: true
				});
			});
		}
	}

	return [task, errors, location, params, options_time, handle_submit];
}

class TaskEdit extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default TaskEdit;