import './Garantii.svelte.css';
/* Garantii.svelte generated by Svelte v3.43.1 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	set_input_value,
	set_style,
	space,
	text,
	transition_in,
	transition_out
} from "./_snowpack/pkg/svelte/internal.js";

import { onMount } from './_snowpack/pkg/svelte.js';
import { user, user_data } from "./stores.js";
import { Router, Route, Link } from "./_snowpack/pkg/svelte-navigator.js";
import Time from "./_snowpack/pkg/svelte-time.js";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[14] = list[i];
	child_ctx[15] = list;
	child_ctx[16] = i;
	return child_ctx;
}

// (144:8) {:else}
function create_else_block_2(ctx) {
	let td;
	let current_block_type_index;
	let if_block;
	let current;
	const if_block_creators = [create_if_block_3, create_if_block_4, create_if_block_5, create_else_block_3];
	const if_blocks = [];

	function select_block_type_1(ctx, dirty) {
		if (/*i*/ ctx[14].dt_finish) return 0;
		if (/*i*/ ctx[14].date_confirmed) return 1;
		if (/*i*/ ctx[14].date1) return 2;
		return 3;
	}

	current_block_type_index = select_block_type_1(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			td = element("td");
			if_block.c();
		},
		m(target, anchor) {
			insert(target, td, anchor);
			if_blocks[current_block_type_index].m(td, null);
			current = true;
		},
		p(ctx, dirty) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type_1(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(td, null);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(td);
			if_blocks[current_block_type_index].d();
		}
	};
}

// (140:8) {#if i.date_confirmed }
function create_if_block_2(ctx) {
	let t0_value = /*i*/ ctx[14].date_confirmed + "";
	let t0;
	let t1;
	let t2_value = /*i*/ ctx[14].time_confirmed + "";
	let t2;

	return {
		c() {
			t0 = text(t0_value);
			t1 = space();
			t2 = text(t2_value);
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, t1, anchor);
			insert(target, t2, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*items*/ 1 && t0_value !== (t0_value = /*i*/ ctx[14].date_confirmed + "")) set_data(t0, t0_value);
			if (dirty & /*items*/ 1 && t2_value !== (t2_value = /*i*/ ctx[14].time_confirmed + "")) set_data(t2, t2_value);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(t1);
			if (detaching) detach(t2);
		}
	};
}

// (160:28) {:else}
function create_else_block_3(ctx) {
	let link;
	let current;

	link = new Link({
			props: {
				to: "/task/" + /*i*/ ctx[14].Id + "/edit",
				class: "button is-primary",
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(link.$$.fragment);
		},
		m(target, anchor) {
			mount_component(link, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const link_changes = {};
			if (dirty & /*items*/ 1) link_changes.to = "/task/" + /*i*/ ctx[14].Id + "/edit";

			if (dirty & /*$$scope*/ 131072) {
				link_changes.$$scope = { dirty, ctx };
			}

			link.$set(link_changes);
		},
		i(local) {
			if (current) return;
			transition_in(link.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(link.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(link, detaching);
		}
	};
}

// (153:28) {#if i.date1}
function create_if_block_5(ctx) {
	let t0_value = /*i*/ ctx[14].date1 + "";
	let t0;
	let t1;
	let t2_value = /*i*/ ctx[14].time1 + "";
	let t2;
	let t3;
	let br0;
	let t4;
	let t5_value = /*i*/ ctx[14].date2 + "";
	let t5;
	let t6;
	let t7_value = /*i*/ ctx[14].time2 + "";
	let t7;
	let t8;
	let br1;
	let t9;
	let t10_value = /*i*/ ctx[14].date3 + "";
	let t10;
	let t11;
	let t12_value = /*i*/ ctx[14].time3 + "";
	let t12;
	let t13;
	let br2;

	return {
		c() {
			t0 = text(t0_value);
			t1 = space();
			t2 = text(t2_value);
			t3 = space();
			br0 = element("br");
			t4 = space();
			t5 = text(t5_value);
			t6 = space();
			t7 = text(t7_value);
			t8 = space();
			br1 = element("br");
			t9 = space();
			t10 = text(t10_value);
			t11 = space();
			t12 = text(t12_value);
			t13 = space();
			br2 = element("br");
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, t1, anchor);
			insert(target, t2, anchor);
			insert(target, t3, anchor);
			insert(target, br0, anchor);
			insert(target, t4, anchor);
			insert(target, t5, anchor);
			insert(target, t6, anchor);
			insert(target, t7, anchor);
			insert(target, t8, anchor);
			insert(target, br1, anchor);
			insert(target, t9, anchor);
			insert(target, t10, anchor);
			insert(target, t11, anchor);
			insert(target, t12, anchor);
			insert(target, t13, anchor);
			insert(target, br2, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*items*/ 1 && t0_value !== (t0_value = /*i*/ ctx[14].date1 + "")) set_data(t0, t0_value);
			if (dirty & /*items*/ 1 && t2_value !== (t2_value = /*i*/ ctx[14].time1 + "")) set_data(t2, t2_value);
			if (dirty & /*items*/ 1 && t5_value !== (t5_value = /*i*/ ctx[14].date2 + "")) set_data(t5, t5_value);
			if (dirty & /*items*/ 1 && t7_value !== (t7_value = /*i*/ ctx[14].time2 + "")) set_data(t7, t7_value);
			if (dirty & /*items*/ 1 && t10_value !== (t10_value = /*i*/ ctx[14].date3 + "")) set_data(t10, t10_value);
			if (dirty & /*items*/ 1 && t12_value !== (t12_value = /*i*/ ctx[14].time3 + "")) set_data(t12, t12_value);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(t1);
			if (detaching) detach(t2);
			if (detaching) detach(t3);
			if (detaching) detach(br0);
			if (detaching) detach(t4);
			if (detaching) detach(t5);
			if (detaching) detach(t6);
			if (detaching) detach(t7);
			if (detaching) detach(t8);
			if (detaching) detach(br1);
			if (detaching) detach(t9);
			if (detaching) detach(t10);
			if (detaching) detach(t11);
			if (detaching) detach(t12);
			if (detaching) detach(t13);
			if (detaching) detach(br2);
		}
	};
}

// (150:24) {#if i.date_confirmed}
function create_if_block_4(ctx) {
	let t0_value = /*i*/ ctx[14].date_confirmed + "";
	let t0;
	let t1;
	let t2_value = /*i*/ ctx[14].time_confirmed + "";
	let t2;

	return {
		c() {
			t0 = text(t0_value);
			t1 = space();
			t2 = text(t2_value);
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, t1, anchor);
			insert(target, t2, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*items*/ 1 && t0_value !== (t0_value = /*i*/ ctx[14].date_confirmed + "")) set_data(t0, t0_value);
			if (dirty & /*items*/ 1 && t2_value !== (t2_value = /*i*/ ctx[14].time_confirmed + "")) set_data(t2, t2_value);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(t1);
			if (detaching) detach(t2);
		}
	};
}

// (147:11) {#if i.dt_finish}
function create_if_block_3(ctx) {
	return {
		c: noop,
		m: noop,
		p: noop,
		i: noop,
		o: noop,
		d: noop
	};
}

// (161:32) <Link to="/task/{i.Id}/edit" class="button is-primary">
function create_default_slot(ctx) {
	let span;
	let t;

	return {
		c() {
			span = element("span");
			t = text(" Lisa ajad");
			attr(span, "class", "mdi mdi-calendar-clock");
		},
		m(target, anchor) {
			insert(target, span, anchor);
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(span);
			if (detaching) detach(t);
		}
	};
}

// (177:8) {:else}
function create_else_block_1(ctx) {
	let button;
	let mounted;
	let dispose;

	function click_handler_1() {
		return /*click_handler_1*/ ctx[8](/*i*/ ctx[14]);
	}

	return {
		c() {
			button = element("button");
			button.innerHTML = `<span class="mdi mdi-checkbox-marked-circle"></span> Valmis`;
			attr(button, "class", "button is-primary");
		},
		m(target, anchor) {
			insert(target, button, anchor);

			if (!mounted) {
				dispose = listen(button, "click", click_handler_1);
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
		},
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

// (176:8) {#if i.dt_finish}
function create_if_block_1(ctx) {
	return { c: noop, m: noop, p: noop, d: noop };
}

// (188:12) {:else}
function create_else_block(ctx) {
	let tr;
	let td0;
	let t0;
	let th;
	let t2;
	let td1;
	let textarea;
	let t3;
	let td2;
	let button;
	let t5;
	let mounted;
	let dispose;

	function textarea_input_handler() {
		/*textarea_input_handler*/ ctx[9].call(textarea, /*each_value*/ ctx[15], /*i_index*/ ctx[16]);
	}

	function click_handler_2() {
		return /*click_handler_2*/ ctx[10](/*i*/ ctx[14]);
	}

	return {
		c() {
			tr = element("tr");
			td0 = element("td");
			t0 = space();
			th = element("th");
			th.textContent = "Kommentaar";
			t2 = space();
			td1 = element("td");
			textarea = element("textarea");
			t3 = space();
			td2 = element("td");
			button = element("button");
			button.innerHTML = `<span class="mdi mdi-checkbox-marked-circle"></span> Lisa kommentaar`;
			t5 = space();
			attr(th, "nowrap", "");
			attr(textarea, "rows", "4");
			attr(textarea, "cols", "80");
			set_style(textarea, "width", "100%");
			attr(td1, "colspan", "4");
			attr(button, "class", "button is-primary");
		},
		m(target, anchor) {
			insert(target, tr, anchor);
			append(tr, td0);
			append(tr, t0);
			append(tr, th);
			append(tr, t2);
			append(tr, td1);
			append(td1, textarea);
			set_input_value(textarea, /*i*/ ctx[14].worker_comment);
			append(tr, t3);
			append(tr, td2);
			append(td2, button);
			append(tr, t5);

			if (!mounted) {
				dispose = [
					listen(textarea, "input", textarea_input_handler),
					listen(button, "click", click_handler_2)
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*items*/ 1) {
				set_input_value(textarea, /*i*/ ctx[14].worker_comment);
			}
		},
		d(detaching) {
			if (detaching) detach(tr);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (187:3) {#if i.dt_finish}
function create_if_block(ctx) {
	return { c: noop, m: noop, p: noop, d: noop };
}

// (128:4) {#each items as i}
function create_each_block(ctx) {
	let tr;
	let th;
	let t0_value = /*i*/ ctx[14].Id + "";
	let t0;
	let t1;
	let span;
	let t2;
	let td0;
	let time;
	let t3;
	let td1;
	let t4_value = /*i*/ ctx[14].addr + "";
	let t4;
	let t5;
	let td2;
	let t6_value = /*i*/ ctx[14].garantii_comment + "";
	let t6;
	let t7;
	let td3;
	let t8_value = /*i*/ ctx[14].worker_name + "";
	let t8;
	let t9;
	let td4;
	let current_block_type_index;
	let if_block0;
	let t10;
	let td5;
	let t11;
	let td6;
	let t12;
	let if_block2_anchor;
	let current;

	time = new Time({
			props: {
				timestamp: new Date(/*i*/ ctx[14].UpdatedAt),
				format: "DD.MM.YYYY"
			}
		});

	const if_block_creators = [create_if_block_2, create_else_block_2];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*i*/ ctx[14].date_confirmed) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block0 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	function select_block_type_2(ctx, dirty) {
		if (/*i*/ ctx[14].dt_finish) return create_if_block_1;
		return create_else_block_1;
	}

	let current_block_type = select_block_type_2(ctx, -1);
	let if_block1 = current_block_type(ctx);

	function select_block_type_3(ctx, dirty) {
		if (/*i*/ ctx[14].dt_finish) return create_if_block;
		return create_else_block;
	}

	let current_block_type_1 = select_block_type_3(ctx, -1);
	let if_block2 = current_block_type_1(ctx);

	return {
		c() {
			tr = element("tr");
			th = element("th");
			t0 = text(t0_value);
			t1 = space();
			span = element("span");
			t2 = space();
			td0 = element("td");
			create_component(time.$$.fragment);
			t3 = space();
			td1 = element("td");
			t4 = text(t4_value);
			t5 = space();
			td2 = element("td");
			t6 = text(t6_value);
			t7 = space();
			td3 = element("td");
			t8 = text(t8_value);
			t9 = space();
			td4 = element("td");
			if_block0.c();
			t10 = space();
			td5 = element("td");
			if_block1.c();
			t11 = space();
			td6 = element("td");
			t12 = space();
			if_block2.c();
			if_block2_anchor = empty();
			attr(span, "class", "mdi mdi-brightness-1");
			set_style(span, "color", /*i*/ ctx[14].color);
			attr(th, "nowrap", "");
			attr(td1, "nowrap", "");
			set_style(td2, "white-space", "break-spaces");
		},
		m(target, anchor) {
			insert(target, tr, anchor);
			append(tr, th);
			append(th, t0);
			append(th, t1);
			append(th, span);
			append(tr, t2);
			append(tr, td0);
			mount_component(time, td0, null);
			append(tr, t3);
			append(tr, td1);
			append(td1, t4);
			append(tr, t5);
			append(tr, td2);
			append(td2, t6);
			append(tr, t7);
			append(tr, td3);
			append(td3, t8);
			append(tr, t9);
			append(tr, td4);
			if_blocks[current_block_type_index].m(td4, null);
			append(tr, t10);
			append(tr, td5);
			if_block1.m(td5, null);
			append(tr, t11);
			append(tr, td6);
			insert(target, t12, anchor);
			if_block2.m(target, anchor);
			insert(target, if_block2_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if ((!current || dirty & /*items*/ 1) && t0_value !== (t0_value = /*i*/ ctx[14].Id + "")) set_data(t0, t0_value);

			if (!current || dirty & /*items*/ 1) {
				set_style(span, "color", /*i*/ ctx[14].color);
			}

			const time_changes = {};
			if (dirty & /*items*/ 1) time_changes.timestamp = new Date(/*i*/ ctx[14].UpdatedAt);
			time.$set(time_changes);
			if ((!current || dirty & /*items*/ 1) && t4_value !== (t4_value = /*i*/ ctx[14].addr + "")) set_data(t4, t4_value);
			if ((!current || dirty & /*items*/ 1) && t6_value !== (t6_value = /*i*/ ctx[14].garantii_comment + "")) set_data(t6, t6_value);
			if ((!current || dirty & /*items*/ 1) && t8_value !== (t8_value = /*i*/ ctx[14].worker_name + "")) set_data(t8, t8_value);
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block0 = if_blocks[current_block_type_index];

				if (!if_block0) {
					if_block0 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block0.c();
				} else {
					if_block0.p(ctx, dirty);
				}

				transition_in(if_block0, 1);
				if_block0.m(td4, null);
			}

			if (current_block_type === (current_block_type = select_block_type_2(ctx, dirty)) && if_block1) {
				if_block1.p(ctx, dirty);
			} else {
				if_block1.d(1);
				if_block1 = current_block_type(ctx);

				if (if_block1) {
					if_block1.c();
					if_block1.m(td5, null);
				}
			}

			if (current_block_type_1 === (current_block_type_1 = select_block_type_3(ctx, dirty)) && if_block2) {
				if_block2.p(ctx, dirty);
			} else {
				if_block2.d(1);
				if_block2 = current_block_type_1(ctx);

				if (if_block2) {
					if_block2.c();
					if_block2.m(if_block2_anchor.parentNode, if_block2_anchor);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(time.$$.fragment, local);
			transition_in(if_block0);
			current = true;
		},
		o(local) {
			transition_out(time.$$.fragment, local);
			transition_out(if_block0);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(tr);
			destroy_component(time);
			if_blocks[current_block_type_index].d();
			if_block1.d();
			if (detaching) detach(t12);
			if_block2.d(detaching);
			if (detaching) detach(if_block2_anchor);
		}
	};
}

function create_fragment(ctx) {
	let h2;
	let t1;
	let div5;
	let div3;
	let article0;
	let div2;
	let div0;
	let input;
	let t2;
	let div1;
	let button;
	let t3;
	let div4;
	let t4;
	let table;
	let thead;
	let t18;
	let tbody;
	let current;
	let mounted;
	let dispose;
	let each_value = /*items*/ ctx[0];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			h2 = element("h2");
			h2.textContent = "Garantii";
			t1 = space();
			div5 = element("div");
			div3 = element("div");
			article0 = element("article");
			div2 = element("div");
			div0 = element("div");
			input = element("input");
			t2 = space();
			div1 = element("div");
			button = element("button");
			button.innerHTML = `<span class="mdi mdi-file-find"></span>`;
			t3 = space();
			div4 = element("div");
			div4.innerHTML = `<article class="tile is-child"></article>`;
			t4 = space();
			table = element("table");
			thead = element("thead");

			thead.innerHTML = `<tr><th>Id</th> 
      <th>Aeg</th> 
      <th>Aadress</th> 
      <th>Kommentaar</th> 
      <th>Vastutaja</th> 
      <th>Pakutud ajad / Kliendi valik</th> 
      <th>Valmis</th></tr>`;

			t18 = space();
			tbody = element("tbody");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(h2, "class", "subtitle");
			attr(input, "class", "input");
			attr(input, "type", "text");
			attr(input, "placeholder", "Otsi");
			attr(div0, "class", "control");
			attr(button, "class", "button is-primary");
			attr(div1, "class", "control");
			attr(div2, "class", "field is-grouped");
			attr(article0, "class", "tile is-child");
			attr(div3, "class", "tile is-parent");
			attr(div4, "class", "tile is-parent");
			attr(div5, "class", "tile is-ancestor");
			attr(table, "class", "table svelte-jx9zwt");
		},
		m(target, anchor) {
			insert(target, h2, anchor);
			insert(target, t1, anchor);
			insert(target, div5, anchor);
			append(div5, div3);
			append(div3, article0);
			append(article0, div2);
			append(div2, div0);
			append(div0, input);
			set_input_value(input, /*search_query*/ ctx[1]);
			append(div2, t2);
			append(div2, div1);
			append(div1, button);
			append(div5, t3);
			append(div5, div4);
			insert(target, t4, anchor);
			insert(target, table, anchor);
			append(table, thead);
			append(table, t18);
			append(table, tbody);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(tbody, null);
			}

			current = true;

			if (!mounted) {
				dispose = [
					listen(input, "input", /*input_input_handler*/ ctx[5]),
					listen(input, "input", /*input_handler*/ ctx[6]),
					listen(button, "click", /*click_handler*/ ctx[7])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*search_query*/ 2 && input.value !== /*search_query*/ ctx[1]) {
				set_input_value(input, /*search_query*/ ctx[1]);
			}

			if (dirty & /*items, handle_comment, handle_complete, Date*/ 25) {
				each_value = /*items*/ ctx[0];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(tbody, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(h2);
			if (detaching) detach(t1);
			if (detaching) detach(div5);
			if (detaching) detach(t4);
			if (detaching) detach(table);
			destroy_each(each_blocks, detaching);
			mounted = false;
			run_all(dispose);
		}
	};
}

let entity = 'garantii';
let tasks_entity = 'task';
let task_url_update = 'https://ncdb.surflink.ee/nc/wcrm_WSoY/api/v1/task/';
let api_key = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFwcF9pbnRlcmlvckB3aW5nbWFjaHQuZWUiLCJmaXJzdG5hbWUiOm51bGwsImxhc3RuYW1lIjpudWxsLCJpZCI6OCwicm9sZXMiOiJ1c2VyIiwiaWF0IjoxNjQ4MDI2NjY2fQ.o8thq4oXWdrlVaOzEBtp9wlfrlySwwX0ecXVXA-gllY';

function instance($$self, $$props, $$invalidate) {
	let $user_data;
	component_subscribe($$self, user_data, $$value => $$invalidate(11, $user_data = $$value));
	let items = [];
	let search_query = '';
	let url_get = 'https://ncdb.surflink.ee/nc/wcrm_WSoY/api/v1/' + entity + '?limit=1000&sort=-id&where=';
	let tasks_url_get = 'https://ncdb.surflink.ee/nc/wcrm_WSoY/api/v1/' + tasks_entity + '?limit=1000&sort=-id&where=';

	onMount(async () => {
		refresh();
	});

	function refresh() {
		let where = "";
		const cols = ['addr', 'garantii_comment', 'garantii_typ', 'worker_name'];

		cols.forEach((v, i, a) => {
			if (i > 0) {
				where += encodeURIComponent('~or');
			}

			where += encodeURIComponent('(' + v + ',like,%' + search_query + '%)');
		});

		//fetch tasks and entich data
		where = '';

		where += encodeURIComponent('(parent,eq,garantii)');
		where += encodeURIComponent('~and(ID_worker,eq,' + $user_data.Id + ')');

		fetch(tasks_url_get + where, { headers: { 'xc-auth': api_key } }).then(response => response.json()).then(data => {
			$$invalidate(0, items = data);
		});
	}

	// TODO: Task ID like GAR-KV3-1 >> func in funcs.js
	// TODO: colors >> func in funcs.js
	// TODO: filter tabs >> filter
	// TODO: Valmis  >> update task
	function handle_complete(item) {
		delete item.UpdatedAt;
		item.color = '#eeeeee';
		item.dt_finish = new Date().toISOString();

		fetch(task_url_update + item.Id, {
			method: 'PUT',
			headers: {
				'xc-auth': api_key,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(item)
		}).then(response => response.json()).then(data => {
			refresh();
		});
	}

	// TODO: Lisa kommentaar (update task)
	function handle_comment(item) {
		delete item.UpdatedAt;

		fetch(task_url_update + item.Id, {
			method: 'PUT',
			headers: {
				'xc-auth': api_key,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(item)
		}).then(response => response.json()).then(data => {
			refresh();
		});
	}

	function input_input_handler() {
		search_query = this.value;
		$$invalidate(1, search_query);
	}

	const input_handler = () => refresh();
	const click_handler = () => refresh();
	const click_handler_1 = i => handle_complete(i);

	function textarea_input_handler(each_value, i_index) {
		each_value[i_index].worker_comment = this.value;
		$$invalidate(0, items);
	}

	const click_handler_2 = i => handle_comment(i);

	return [
		items,
		search_query,
		refresh,
		handle_complete,
		handle_comment,
		input_input_handler,
		input_handler,
		click_handler,
		click_handler_1,
		textarea_input_handler,
		click_handler_2
	];
}

class Garantii extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Garantii;